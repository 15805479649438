// src/js/components/List.jsx
import React from "react";
import { connect } from "react-redux";



const mapStateToProps = state => {
  return {
          error:state.error };
};


const ErrorCheck = ({ error }) => (

<div>
{error}
</div>
);


const Error = connect(mapStateToProps)(ErrorCheck);
export default Error;
